<template>
  <div>
    <div class="text-center m-b-10">
      <DatePicker placeholder="选择发布档期" size="small" v-model="dateRage" type="daterange" split-panels style="width:220px"
        :editable="false" @on-change="onChangeDate"></DatePicker>
    </div>
    <div v-show="showChart" class="p-t-20" style="width:100%;height:300px;" id="chart_industry">
    </div>
    <div v-show="!showChart" class="default-content">
      <p style="font-size: x-large;">暂无数据</p>
    </div>
  </div>
</template>

<script>
import { caseLibraryStatMixins } from '../../mixins/statistics'
import { sysMixins } from '@/assets/mixins/sys'
export default {
  mixins: [sysMixins, caseLibraryStatMixins],
  data () {
    return {
      showChart: false,
      dateRage: [],
      query: {
        statisticType: 1,
        startDate: '',
        endDate: ''
      }
    }
  },
  created () {
    this.initData()
  },
  methods: {
    async initData () {
      this.showChart = false
      const result = await this.getCaseLibraryStatData(this.query)
      let chartOption = {}
      if (result && result.length) {
        this.showChart = true
        chartOption = {
          title: {
            text: '按行业分类统计',
            left: 'center',
            textStyle: {
              color: this.reportFontColor
            }
          },
          textStyle: {
            color: this.reportFontColor
          },
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: 'bottom',
            textStyle: {
              color: this.reportFontColor
            }
          },
          series: [
            {
              type: 'pie',
              radius: '70%',
              data: result.map(x => {
                return { name: x.name, value: x.totalCount }
              })
            }
          ]
        }
      }

      this.initChart('chart_industry', chartOption, this.showChart)
    },
    onChangeDate (date) {
      this.query.startDate = date[0]
      this.query.endDate = date[1]
      this.initData()
    }
  }
}
</script>
