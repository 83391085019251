import * as echarts from 'echarts'
import { getStatisticList } from '@/api/msp/materiallibrary'

export const caseLibraryStatMixins = {
  data () {
    return {
      chart: null
    }
  },

  methods: {
    /**
         * 按行业、产品、站点统计案例数量
         * @param {Object} query 查询参数
         * @returns
         */
    async getCaseLibraryStatData (query) {
      return await getStatisticList(query)
    },
    /**
     * 初始化图标
     * @param {String} chartId 图标的id名
     * @param {*} option 图标配置
     * @param {*} showChart 是否显示图标
     */
    initChart (chartId, option, showChart = true) {
      this.$nextTick(() => {
        let chart = echarts.getInstanceByDom(document.getElementById(chartId))
        if (chart == null) { // 如果不存在，就进行初始化
          chart = echarts.init(document.getElementById(chartId))
        }
        if (showChart) {
          chart.setOption(option, true)
          window.addEventListener('resize', function () {
            chart.resize()
          })
        } else {
          chart.dispose()
        }
      })
    }
  }

}
