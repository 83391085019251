<template>
  <div>
    <Row :gutter="8" class="m-b-10">
      <i-col span="12">
        <Card>
          <industry-stat />
        </Card>
      </i-col>
      <i-col span="12">
        <Card>
          <product-stat />
        </Card>
      </i-col>
    </Row>
    <Card class="m-b-10">
      <station-stat />
    </Card>
  </div>
</template>

<script>
import IndustryStat from './statistics/Industry.vue'
import ProductStat from './statistics/Product.vue'
import StationStat from './statistics/Station.vue'
export default {
  components: {
    IndustryStat,
    ProductStat,
    StationStat
  }
}
</script>
